$hamburgerWidth: 36rem;

.header {
  width: 100%;
  overflow-y: visible;
  overflow-x: clip;
  background-color: $page-bg;
  z-index: 10;
  transition: all 0.5s ease;

  &__wrapper {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__subNavigation {
    display: flex;
    justify-content: flex-end;
    padding: 0 $spc6;
    background: $page-bg;

    & .hamburger-menu__subNav-item {
      padding: 1.4rem 1.2rem;
      color: $primary;

      svg {
        fill: $primary-2;
      }

      &:hover {
        color: $primary-2;
      }
    }
  }

  &__inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: $white;

    @include xl-up {
      justify-content: flex-start;
      align-items: center;
      background-color: $white;
    }
  }

  .header__logo {
    @include flex-center-center;
    z-index: 20;

    @include xl-up {
      padding: $spc3 $spc2 $spc3 $spc6;
    }
  }
  
  $height-icon: 1.2rem;
  $width-line: 1.8rem;
  $height-line: 0.2rem;

  $rotation: 45deg;
  $translateY: calc($height-icon / 2);
  $translateX: 0;

  .hamburger {
    @include flex-center-center;
    padding: 1.9rem;
    z-index: 101;
  }

  .nav__desktop {
    align-items: center;
  }

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;
    cursor: pointer;
    z-index: 20;
    
    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: calc($height-line / 4);
      transition: all 0.3s;
      background-color: $primary;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
          transform: translateY(calc($height-line / 2 * -1));
      }
      .line-3 {
          transform: translateY(calc($height-line / 2));
      }  
    }
    &.active {

      .line {
        box-shadow: none;
        background-color: $primary;
      }
      
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY($translateY * -1) translateX($translateX) rotate($rotation * -1);
      }
    }
  }

  .hamburger-menu {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    transform: translate(100%, 0%);
    transition: 0.3s transform ease;
    background: $white;
    color: $primary;
    z-index: 10;

    @include md-up {
      width: $hamburgerWidth;
    }

    &__inner {
      padding: 2.4rem 3.2rem 4rem 6.6rem;
    }

    a,
    button {
      font-size: 1.6rem;
      font-weight: 800;
      line-height: 100%; /* 16px */
      color: $primary;
      background: transparent;
      text-transform: uppercase;
      padding: 1.2rem 0;

      @include md-up {
        padding: 1.6rem 0;
      }

      &:hover,
      &.active {
        color: $cta;
      }

      svg {
        @include squaresize(1.6rem);
      }
    }

    &__submenu {
      a,
      button {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 18.9px;
        padding: 0.8rem 0 0.8rem 2.4rem;
      }
    }

    &__separator {
      height: 2px;
      width: 100%;
      background: $gray-100;
      margin: 0.8rem 0;
    }

    &__footer {
      display: flex;
      flex-direction: column;

      svg {
        fill: $primary-2;
      }
    }
  }

  &.active {
    .hamburger-menu {
      transform: translate(0, 0%);
      transition: 0.3s transform ease;
    }
  }
}
