.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}

// Background colors
.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-white {
  background-color: $white !important;
}

.bg-black {
  background-color: $black !important;
}

.bg-gray-100 {
  background-color: $gray-100 !important;
}

.bg-gray-200 {
  background-color: $gray-200 !important;
}

.bg-gray-300 {
  background-color: $gray-300 !important;
}

.bg-gray-400 {
  background-color: $gray-400 !important;
}

// Text colors
.color-primary {
  color: $primary;
}
.color-secondary {
  color: $secondary;
}
.text-white {
  color: $white;
}

.link-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

// cursort pointer
.c-p {
  cursor: pointer;
}

.bg-white {
  background: $white;
} 

.bg-lightBlue {
  background: $page-bg;
} 

.bg-blue {
  background: $blue;

  &.hero__content {
    background: $gradient-company;
  }
} 

.bg-finance {
  background: $finance;

  &.hero__content {
    background: $gradient-finance;
  }
} 

.bg-market {
  background: $market;

  &.hero__content {
    background: $gradient-market;
  }
} 

.bg-summaries {
  background: $summaries;

  &.hero__content {
    background: $gradient-summaries;
  }
} 

.bg-sustainability {
  background: $sustainability;

  &.hero__content {
    background: $gradient-sustainability;
  }
} 

.bg-knowledge {
  background: $knowledge;

  &.hero__content {
    background: $gradient-knowledge;
  }
} 

.bg-blue *,
.bg-finance *,
.bg-market *,
.bg-summaries *,
.bg-sustainability *,
.bg-knowledge * {
  color: $white;
} 

.bg-blue .section-title:not(.kpi__card_title, .dataAndCharts__chart_title),
.bg-finance .section-title:not(.kpi__card_title, .dataAndCharts__chart_title),
.bg-market .section-title:not(.kpi__card_title, .dataAndCharts__chart_title),
.bg-summaries .section-title:not(.kpi__card_title, .dataAndCharts__chart_title),
.bg-sustainability .section-title:not(.kpi__card_title, .dataAndCharts__chart_title),
.bg-knowledge .section-title:not(.kpi__card_title, .dataAndCharts__chart_title) {
  color: rgba($white, 0.6);
}

.bg-blue .kpi__card,
.bg-finance .kpi__card,
.bg-market .kpi__card,
.bg-summaries .kpi__card,
.bg-sustainability .kpi__card,
.bg-knowledge .kpi__card {
  border-top: 2px dotted $white;
}

.bg-blue .quote__sign,
.bg-finance .quote__sign,
.bg-market .quote__sign,
.bg-summaries .quote__sign,
.bg-sustainability .quote__sign,
.bg-knowledge .quote__sign {
  color: rgba($white, 0.8);
}

.image-corner {
  border-radius: $media-corner;
}

.card-corner {
  border-radius: $card-corner;
}


.notFound {
  a {
    color: $primary;
    font-size: 2.4rem;
    margin: 0 auto;
    display: block;
    font-family: $font-family-sans-serif;
  }
}
