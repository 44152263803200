html {
  // important to set the html base to 10px
  font-size: 62.5%;
  box-sizing: border-box;

}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem;
  margin: 0;
  padding: 0;
}

#main {
  background: $page-bg;
  padding-bottom: $spc9;
}

.row {
  margin: 0;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Media queries
// Phones
$screen-sm-min: 360px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 1024px;


$screen-ios-min: 1367px;

// Large tablets and desktops
$screen-xl-min: 1440px;

// Extra large screens
$screen-xxl-min: 1920px;

.section.container {
  max-width: 1440px;
}

//selecting Text on website
::-moz-selection { /* Code for Firefox */
  color: #ffffff;
  background: $primary;
}

::selection {
  color: #ffffff;
  background: $primary;
}

//custom scrollbar
::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: $primary; 
  border-radius: 1rem;
  transition: all 0.3s ease;
}
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.container_wrapper {
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: $spc2 $spc3;

  @include md-up {
    padding: $spc3 $spc6;
  }
  @include lg-up {
    padding: $spc3 $spc7;
  }
  @include xl-up {
    padding: $spc6;
  }
}

#CybotCookiebotDialogHeader {
  display: none !important;
}

#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

.CookieDeclaration {
  display: none !important;
}



